<template>
	<div class="fill-height">
		<img
			src="/images/background.png"
			width="100%" height="100%"
			style="position: absolute;"
		>
		<img
			src="/images/black-screen.png"
			width="100%" height="100%"
			style="position: absolute;"
		>
		<v-container class="text-center justify-center fill-height">
			<v-row 
				justify="center" dense 
				class="px-3" style="z-index: 1;"
			>
				<v-col cols="12" lg="5">
					<v-row justify="center" class="mb-3">
						<v-col cols="12" sm="6" md="6">
							<img src="/images/logo.svg" width="100%">
						</v-col>
					</v-row>
					<v-row justify="center">
						<v-card width="75%" class="radius-20">
							<v-card-text
								class="pa-6"
								:class="$vuetify.breakpoint.mdAndUp ? 'px-16' : ''"
							>
								<div class="title pb-6">DEALER LOGIN</div>
								<v-text-field
									v-model="user.email"
									label="EMAIL"
									outlined
									dense filled
									class="radius-10"
									color="secondary"
									:error-messages="errors.email"
									@keyup.enter="$refs['password'].focus()"
								></v-text-field>
								<v-text-field
									ref="password"
									v-model="user.password"
									:type="show_pass ? 'text' : 'password' "
									label="PASSWORD"
									outlined
									dense filled
									class="radius-10"
									color="secondary"
									:error-messages="errors.password"
									@keyup.enter="login()"
								>
									<template #append>
										<v-btn small icon @click="show_pass = !show_pass">
											<v-icon small>{{ show_pass ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
										</v-btn>
									</template>
								</v-text-field>
								<div class="caption error--text font-italic px-10">
									{{ error_else }}
								</div>
								<v-btn 
									block large
									depressed
									color="primary" 
									class="black--text title rounded-lg"
									:loading="isLoading" 
									@click="login()"
								>
									Login
								</v-btn>
								<div class="mt-4 pb-10">
									<!-- <router-link
										to="/sign-up"
										class="caption black--text text-decoration-none font-italic float-left"
									>
										Sign Up
									</router-link> -->
									<router-link
										to="/forgot-password"
										class="caption black--text text-decoration-none font-italic float-right"
									>
										Forgot password
									</router-link>
								</div>
								<div class="mt-5 px-4">
									<v-btn
										text
										class="black--text body-2"
										:loading="isLoading" 
										@click="login_guest()"
									>
										Skip for now
									</v-btn>
								</div>
							</v-card-text>
						</v-card>
					</v-row>
					<v-row justify="center" class="mb-3 mt-5">
						<v-col cols="12" sm="6" md="6">
							<v-btn
								text
								class="yellow--text body-2"
								:loading="isLoading" 
								@click="redirectConsumerLogin()"
							>
								Go to consumer login
							</v-btn>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { errorHandlerMixin } from "@/mixins/ErrorHandlerMixin"
import { mapGetters, mapActions } from "vuex"

export default {
	mixins: [ 
		errorHandlerMixin
	],
	data() {
		return {
			title: process.env.MIX_APP_NAME,
			user:{
				email : null,
				password : null
			},
			errors : {},
			error_else: null,
			isLoading : false,
			show_pass: false,
		}
	},
	computed: {
		...mapGetters(["redirectAfterLogin"]),
	},
	mounted() {
		//Mount the method to be called natively on the window 
		window.login = this.login
		window.oauth2_verify = this.oauth2_verify
		window.login_guest = this.login_guest
		window.redirect_mobile_login = this.redirect_mobile_login
	},
	created(){
		// check is logined
		if(this.$auth.check()){
			this.redirectUser(this.$auth.user().role_id)
		}

		//redirect to /login
		if(window.location.pathname == '/' && !this.$auth.check())
			this.login_guest()

		// await this.axios.get({ url: '/sanctum/csrf-cookie', baseURL: '/' }) // reuqired for cross-domain
	},
	methods:{
		login(details = null){
			this.errors = {}
			this.error_else = null
			this.isLoading = true;

			this.$auth.login({
				data: details ?? {
					email: this.user.email,
					password: this.user.password,
					dkWebLogin: true,
				},
				staySignedIn: true,
				fetchUser: true,
			}).then((res)=>{
				let fetchUserResult = res.data.data
				this.redirectUser(fetchUserResult.role_id)
			}).catch((err)=>{
				console.log(err)
				this.$toast.warning('Invalid login details')
			}).finally(()=>{
				this.isLoading = false
			});
		},
		login_oauth(type){
			this.errors = {}
			this.isLoading = true;

			this.$auth.oauth2(type, {
				params: {
					redirect_uri: 'login/'+type,
					response_type: 'code,token',
					state: {
						remember: false,
						staySignedIn: true,
						fetchUser: true,
					},
				},
				window: {
					name: '_blank',
					specs: {},
					replace: false
				},
			}).then((res)=>{
				let fetchUserResult = res.data.data
				this.redirectUser(fetchUserResult.role_id)
			}).catch((err)=>{
				this.$toast.warning('Something went wrong.')
			}).finally(()=>{
				this.isLoading = false
			});
		},
		oauth2_verify(type, token){
			this.$auth.oauth2(type, {
				url: 'auth/'+type+'/login',
				code: true,
				data: {
					access_token: token
				},
				state: this.$route.query.state,
			}).then((res)=>{
				let fetchUserResult = res.data.data
				this.redirectUser(fetchUserResult.role_id)
			}).catch((err)=>{
				console.log(err)
				this.$toast.warning('Something went wrong.')
			}).finally(()=>{
				this.isLoading = false
			});
		},
		login_guest(){
			this.login({
				email: "developer@dk-schweizer.com",
				password: "BsjXL94fIXZn887OhFsfgQ",
			})
		},
		redirectUser(role_id = null){
			let home = {
				"3" : "/new-order",
			}

			if(this.redirectAfterLogin)
				return this.$router.push("/new-order/customization")
			else
			{
				if(home[role_id])
					return this.$router.push(home[role_id])
				
				return this.$router.push("/order-histories")
			}
		},
		oauth_apple()
		{
			document.getElementById('appleid-signin').click()
		},
		callSuccess(data)
		{
			this.$auth.oauth2('apple', {
				url: 'auth/apple/login',
				code: true,
				data: {
					access_token: data.authorization.id_token
				},
				state: 'STATE',
			}).then((res)=>{
				let fetchUserResult = res.data.data
				this.redirectUser(fetchUserResult.role_id)
			}).catch((err)=>{
				console.log(err)
				this.$toast.warning('Something went wrong.')
			}).finally(()=>{
				this.isLoading = false
			});
		},
		callFail(data)
		{
			this.$toast.warning('Something went wrong.')
		},
		redirectConsumerLogin(){
			this.$router.push("/login-consumer")
		},
		redirect_mobile_login(){
			this.$router.push("/mobile-login")
		},
	}
}
</script>